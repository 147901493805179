import React from 'react';
import { EnumErrorType, ErrorObjectContent } from '../../types/ErrorsObject';
import { useTranslation } from 'react-i18next';
import ErrorImage from './ErrorImage';
import ErrorPreContent from './ErrorPreContent';
import ReferenceNumber from './ReferenceNumber';
import ErrorContent from './ErrorContent';
import { useAppSelector } from '../../app/hooks';
import { CustomizationKey, selectCustomizations } from '../../app/slices/customizations.slice';
import { errorsMap } from './errors';
import TextCustomization from '../customizations/TextCustomization';

const TRANSLATION_PREFIX = 'technical-error-page';

interface ErrorStepProps {
	errorType: EnumErrorType;
}

const getErrorStepTitleCustomizationKey = (errorType: EnumErrorType): CustomizationKey => {
	switch (errorType) {
		case EnumErrorType.REACT_ERROR:
		case EnumErrorType.APP_ERROR:
			return 'TECHNICAL_ERROR_PAGE_TITLE';
		case EnumErrorType.CHECK_EXPIRED:
			return 'CHECK_EXPIRED_ERROR_PAGE_TITLE';
		case EnumErrorType.VALIDATION_TOKEN_NO_LONGER_VALID_ERROR:
			return 'VALIDATION_TOKEN_EXPIRED_ERROR_PAGE_TITLE';
		case EnumErrorType.KLARNA_ERROR:
			return 'KLARNA_LOGIN_ERROR_PAGE_TITLE';
		case EnumErrorType.LOAD_ISSUES_ERROR:
			return 'LOAD_ISSUES_NOTICE_PAGE_TITLE';
		default:
			throw new Error('Unsupported error type occurred.');
	}
};

const ErrorStep = ({ errorType }: ErrorStepProps) => {
	const { t } = useTranslation();
	const { title } = errorsMap.get(errorType) as ErrorObjectContent;
	const translatedTitle = t(`${TRANSLATION_PREFIX}.${title}`);
	const customizations = useAppSelector(selectCustomizations);

	return (
		<div className="error-step">
			<div className="error-step-message">
				<ErrorPreContent type={errorType} />
				<ErrorImage type={errorType} />
				<div className="error-step-title">
					<div className="error-step-title">
						{Object.keys(customizations).length === 0 ? (
							<>{translatedTitle}</>
						) : (
							<TextCustomization id={getErrorStepTitleCustomizationKey(errorType)} />
						)}
					</div>
				</div>
				<br />
				<ErrorContent errorType={errorType} />
				<br />
				<ReferenceNumber type={errorType} />
				<br />
			</div>
		</div>
	);
};

export default ErrorStep;
