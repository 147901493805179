import { AxiosResponseInterceptor } from './types';
import { HttpHeader, HttpStatus } from '../constants/http';
import store from '../app/store';
import { selectLongTermJWT, setShortTermJWT } from '../app/slices/session.slice';
import { AxiosError, AxiosResponse } from 'axios';
import { TokenService } from '../services/TokenService';
import { ApiService } from './axios';

export const AuthorizationRefreshInterceptor: AxiosResponseInterceptor = {
	onRejected: async (error) => {
		if (!isAuthorizationTokenExpired(error)) throw error;

		try {
			const longTermToken = getLongTermToken();
			const token = await fetchRefreshedShortTermToken(longTermToken);

			store.dispatch(setShortTermJWT(token));
			return await ApiService.request(error.config);
		} catch (err) {
			throw err;
		}
	}
};

const isAuthorizationTokenExpired = (error: any): boolean => {
	const response = error.response;
	return response && response.status === HttpStatus.ACCESS_FORBIDDEN;
};

const getLongTermToken = () => {
	const token = selectLongTermJWT(store.getState());
	if (token === '') throw new Error('Long term token not defined inside request authorization refresh interceptor');
	return token;
};

const fetchRefreshedShortTermToken = async (token: string) => {
	const response = await new TokenService().fetchAuthorizationToken(token);

	if (!isTokenRefreshedResponse(response)) {
		throw new Error(`Unsupported response on token refresh: ${response.status}`);
	}

	return getRefreshedToken(response);
};

const isTokenRefreshedResponse = (response: AxiosResponse): boolean => {
	return response.status === HttpStatus.RESET_CONTENT;
};

const getRefreshedToken = (response: AxiosResponse) => {
	const headers = response.headers;
	const token = headers[HttpHeader['x-check-token']];
	if (!token) throw new Error('Refresh short term token not defined on HTTP 205 Reset');
	return token;
};

export const RefreshedTokenInterceptor: AxiosResponseInterceptor = {
	onFulfilled: async (response) => {
		if (isTokenRefreshedResponse(response)) {
			const token = getRefreshedToken(response);
			store.dispatch(setShortTermJWT(token));

			const config = response.config;
			return await ApiService.request(config);
		}

		return response;
	}
};

export const GatewayErrorInterceptor: AxiosResponseInterceptor = {
	onRejected: (error: AxiosError): Promise<never> => {
		if (error.response && isGatewayResponseError(error.response)) {
			return Promise.reject(Object.assign(new Error('Gateway error'), { status: error.response.status }));
		}
		return Promise.reject(error);
	}
};

const isGatewayResponseError = (response: AxiosResponse): boolean => {
	const status = response.status;
	return (
		status === HttpStatus.BAD_GATEWAY ||
		status === HttpStatus.SERVICE_UNAVAILABLE ||
		status === HttpStatus.GATEWAY_TIMEOUT
	);
};
