import React, { useEffect } from 'react';
import { WithChildren } from './SingleSpinner';
import { ErrorBoundary } from 'react-error-boundary';
import { EnumErrorType } from '../../types/ErrorsObject';
import { useAppDispatch } from '../../app/hooks';
import ErrorStepsWrapper from '../error/ErrorStepsWrapper';
import { errorsActions } from '../../app/slices/errors.slice';
import NewRelicAgent from '../../newrelic';

type AppErrorBoundaryProps = WithChildren<{
	Fallback?: (props: any) => JSX.Element;
}>;

export const AppErrorBoundary = ({ children, Fallback = ErrorStepsWrapper }: AppErrorBoundaryProps) => {
	const dispatch = useAppDispatch();

	return (
		<ErrorBoundary
			FallbackComponent={(props) => {
				useEffect(() => {
					NewRelicAgent.noticeError(props.error, { message: props.error?.message || 'Unknown message' });
					dispatch(
						errorsActions.setError({
							type: EnumErrorType.REACT_ERROR,
							error: props.error
						})
					);
				}, [props.error]);

				return (
					<React.Fragment>
						<Fallback {...props} />
					</React.Fragment>
				);
			}}
		>
			{children}
		</ErrorBoundary>
	);
};
